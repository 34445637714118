export default {
 weekWorkingHours: {
  start: '9.00',
  end: '17.00',
 },
 saturdayWorkingHours: {
  text: 'Po wcześniejszym uzgodnieniu'
 },
 contact: [
  'PediBello',
  'Gabinet Podologiczny',
  'Urszula Piekarska',
  'ul. Mostowa 12G',
  '16-300 Augustów',
  'Tel. 8888 9999 0',
  'podolog.augustow@gmail.com'
 ]
}