import React from 'react'
import styled from 'styled-components'
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Rectangle } from "react-google-maps"
import apiKey from '../../../apiKey'

const MyMapComponent = compose(
 withProps({
  googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `100%` }} />,
  mapElement: <div style={{ height: `100%` }} />,
 }),
 withScriptjs,
 withGoogleMap
)((props) =>
 <GoogleMap
  defaultZoom={16}
  defaultCenter={{ lat: 53.845175, lng: 22.982711 }}
 >
  {props.isMarkerShown && <Marker position={{ lat: 53.845175, lng: 22.982711 }} />}
 </GoogleMap>
)

export default MyMapComponent