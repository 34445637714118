import { Link } from 'gatsby';
import { map } from 'ramda'
import React from 'react';
import menuOptions from '../siteConfig/menuOptions'
import styled from 'styled-components'
import Hamburger from './Hamburger';
import { color2, color6, color1 } from '../constants/colors'

const MenuList = styled.ul`
  position: fixed;
  display: inline-block;
  list-style: none;
  padding-left: 25px;
  transition: .45s;
  z-index: 200;
  @media(max-width: 1023px){
    ${props => props.showMenu ? 'opacity: 1;' : 'opacity: 0;'}
  }
  @media(min-width: 1024px){
    top: 34px;
    right: 0;
    background-color: ${color2};
    padding-top: 20px;
    padding-right: 10px;
    opacity: 1;
    transform: ${props => props.showMenu ? 'scaleY(1);' : 'scaleY(0);'}
    transform-origin: 50% 0;
  }
`

const MenuItem = styled.li`
  margin-bottom: 25px;
    @media(min-width: 1024px){
    text-align: right;
  }
`

const MenuItemLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  color: ${color6};
  transition: .3s;
  &:hover {
    color: ${color1};
  }
`

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 200;
  display: flex;
  flex-direction: column;
  height: 50px;
  background-color: ${color2};
  color: ${color6};
  transition: .45s;
  @media(max-width: 1023px){
  ${props => props.showMenu ? 'height: 100vh;' : null}
  }`

const NavContainer = styled.div`

`

const TopBelt = styled.div`
  height: 50px;
`

const Menu = ({ showMenu, setShowMenu }) => {
  return (
    < StyledNav showMenu={showMenu} >
      <NavContainer>
        <TopBelt>
          <Hamburger showMenu={showMenu} setShowMenu={setShowMenu} />
        </TopBelt>

        <div>
          <MenuList onClick={e => e.stopPropagation()} showMenu={showMenu}>
            {map(
              option =>
                <MenuItem key={option.link}>
                  <MenuItemLink
                    onClick={() => {
                      setShowMenu(!showMenu)
                    }}

                    to={option.link}
                  >{option.title}
                  </MenuItemLink>
                </MenuItem>,
              menuOptions)}
          </MenuList>
        </div>
      </NavContainer>
    </StyledNav >
  )
};

export default Menu;
