import React from 'react'
import styled from 'styled-components'
import { color6 } from '../../constants/colors'

const Belka1 = styled.div`
background-color: ${color6};
width: 100%;
height: 3px;
transition: .45s;
transform-origin: 0% 50%;
${props => props.showMenu ? 'transform: rotate(45deg);' : null}
`

const Belka2 = styled.div`
background-color: ${color6};
width: 100%;
margin: 3px auto;
height: 3px;
transition: .45s;
${props => props.showMenu ? 'width: 0;' : null}
`

const Belka3 = styled.div`
 background-color: ${color6};
 width: 100%;
 height: 3px;
 transform-origin: 0% 50%;
 transition: .45s;
 ${props => props.showMenu ? 'transform: rotate(-45deg);' : null}
`

const Icon = styled.div`
 position: absolute;
 top: 13px;
 right: 30px;
 width: 24px;
 height: 22px;
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 z-index: 999;
 cursor: pointer;
`

const HamburgerIcon = ({ onClick, showMenu }) => {
 return (
  <Icon onClick={onClick}>
   <Belka1 showMenu={showMenu} />
   <Belka2 showMenu={showMenu} />
   <Belka3 showMenu={showMenu} />
  </Icon>
 )
}

export default HamburgerIcon
