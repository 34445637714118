export const color1 = '#e1d7cd'
export const color2 = '#6c5f54'
export const color3 = 'rgba(225, 215, 205, 0.77)'
export const color4 = '#BFB4AA'
export const color5 = 'rgba(191, 180, 170, 0.45)'
export const color6 = '#f0ebe8'
export const color7 = '#ECE8DF'
export const color8 = 'rgba(196, 42, 53, 0.4)'
export const color9 = '#C42A35'
export const color10 = '#000'
export const color12 = '#413c38'
export const color11 = 'rgba(65,60,56, 0.2)'
export const color13 = '#BFB4AB'
export const color14 = '#f1f1f1'