import React from 'react';
import Layout from "../components/Layout"
import SEO from '../components/Seo';
import Contact from "../components/HomePage/Contact"

import styled from 'styled-components'

import MaxWidthContainer from '../components/Layout/MaxWidthContainer'

import { color1, color12 } from '../constants/colors'
import { header } from '../content/certificatesContent'
import gallery from '../content/gallery'
import { map } from 'ramda'

import GalleryModal from '../components/GalleryModal'

const GalleryHeader = styled.h4`
 margin: 0;
 text-align: center;
 font-family: Roboto, sans-serif;
 font-size: 12px;
 letter-spacing: 1px;
 @media(min-width: 600px){
  font-size: 18px;
  padding-bottom: 15px;
 }
 @media(min-width: 1024px){
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 30px;
 }
`

const CertificatesContainer = styled.div`
 background-color: ${color1};
 height: calc(100% - 90px);
 margin: 0;
 padding-top: 20px;
 padding-bottom: 50px;
`

const GalleryList = styled.ul`
 list-style: none;
 padding: 0;
 max-width: 80%;
 margin: 15px auto;
 display: grid;
 grid-gap: 20px;
 grid-template-rows: repeat(6, 1fr);
 grid-template-columns: 1fr;
 @media(min-width: 600px){
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2 ,1fr);
 }
 @media(min-width: 1024px){
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3 ,1fr);
 }
`

const GalleryListPhotoItem = styled.li`
 width: 100%;
 
 cursor: pointer;
`

const GalleryPhoto = styled.img`
 width: 100%;
`

const renderGallery = (setModal, setDarkX, setVertical) => map(({ photo, thumbnail, darkX, vertical }) => (
 <GalleryListPhotoItem>
  <GalleryPhoto src={thumbnail} onClick={() => {
   setDarkX(darkX)
   setModal(photo)
   setVertical(vertical)
  }} />
 </GalleryListPhotoItem>
), gallery)

const IndexPage = () => {
 const [modal, setModal] = React.useState(null)
 const [darkX, setDarkX] = React.useState(false)
 const [vertical, setVertical] = React.useState(false)

 return (
  <Layout >
   {modal &&
    <GalleryModal photo={modal} setModal={setModal} darkX={darkX} vertical={vertical} />
   }
   <SEO title="Galeria zdjęć" />
   <CertificatesContainer>
    <MaxWidthContainer>
     <GalleryHeader>{header}</GalleryHeader>
     <GalleryList>
      {renderGallery(setModal, setDarkX, setVertical)}
     </GalleryList>
    </MaxWidthContainer>
   </CertificatesContainer>
   <Contact />
  </Layout>
 );
}

export default IndexPage;
