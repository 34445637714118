import React from 'react'
import Image from '../images/PEDI-BELLO-logo.png'
import styled from 'styled-components'

const Img = styled.img`
  height: 100px;
  margin: auto 10px auto auto;
  @media (min-width: 600px) {
   height: 150px;
  }
  @media (min-width: 1024px) {
   height: 200px;
  }
`

const Logo = () => {
  return <Img src={Image} />
}

export default Logo
