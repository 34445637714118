import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
 position: fixed;
 background: transparent;
    top:0;
    bottom:0;
    left:0;
    right:0;
    overflow:hidden;
 z-index: ${props => props.visible ? '199' : '-222'};
`

const Overlay = ({ visible, onClick, children }) => <StyledDiv visible={visible} onClick={onClick}>{children}</StyledDiv>

export default Overlay
