const menuOptions = [
  {
    link: '/',
    title: 'Strona Główna',
  },
  {
    link: '/strefa-pacjenta',
    title: 'Strefa Pacjenta',
  },
  {
    link: '/cennik',
    title: 'cennik',
  },
  {
    link: '/galeria',
    title: 'Galeria zdjęć',
  },
  {
    link: '/#kontakt',
    title: 'kontakt',
  },
]

export default menuOptions