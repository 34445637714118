import React from 'react'
import styled from 'styled-components'
import MaxWidthContainer from '../components/Layout/MaxWidthContainer'
import { color12, color1, color2 } from '../constants/colors'
import { FaWindowClose } from 'react-icons/fa'

const Overlay = styled.div`
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 800;
 background: rgba(0,0,0,0.45);
`

const PhotoContainer = styled.div`
 border: 1px solid ${color1};
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 overflow: hidden;
 ${props => props.vertical ?
    `
  height: 90%;
  min-width: 40%;
  @media(orientation: portrait) {
   min-width: 90%;
  }
  @media(orientation: portrait) and (min-width: 768px) {
   min-width: 70%;
  }
  @media(min-width: 1280px){
  min-width: 35%;
  }
  @media(min-width: 1440px){
  min-width: 30%;
  }
 ` :
    `
  height: 80%;
  width: 80%;
  @media(min-width: 820px){
  height: 400px;
  width: 815px;
  }
  @media(orientation: portrait) {
   width: 90%;
   max-height: 30%;
  }
  @media(min-width: 1280px){
   width: 1200;
   height: 600px;
   max-height: 40%;
  }
 `
  }
 background-image: url(${props => props.image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
`

const Photo = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`

const PhotoClose = styled(FaWindowClose)`
 cursor: pointer;
 position: absolute;
 top: 20px;
 right: 20px;
 font-size: 20px;
 @media(min-width: 768px) {
 font-size: 30px;
  }
 z-index: 999;
 color: ${props => props.darkX ? color2 : color1};
`

const GalleryModal = ({ photo, setModal, darkX, vertical }) => {
  return (
    <Overlay onClick={() => setModal('')}>
      <PhotoContainer onClick={e => e.stopPropagation()} image={photo} vertical={vertical}>
        <PhotoClose darkX={darkX} onClick={() => setModal('')} />
      </PhotoContainer>
    </Overlay>
  )
}

export default GalleryModal

