import React from 'react'
import styled from 'styled-components'
import { color14, color12, color2, color1 } from '../../constants/colors'
import Map from './Map'
import contactInfo from '../../content/contactInfo'
import { map } from 'ramda'
import outOfContainer from '../Layout/outOfContainer'
import MaxWidthContainer from '../Layout/MaxWidthContainer'
import Logo from '../../components/Logo'

const Wrapper = styled.div`
 background-color: ${color1};
 display: flex;
 flex-direction: column;
`

const MapContainer = styled.div`
 height: 300px;
  @media (min-width: 600px) {
  height: 400px;
  }
`
const ContactBox = styled.div`
 flex-grow: 1;
`
const Header = styled.h4`
 color: ${color14};
 background-color: ${color2};
 font-family: Roboto, sans-serif;
 font-weight: 700;
 padding: 20px;
 margin: 0;
 text-align: center;
  @media (min-width: 600px) {
   font-size: 23px;
  }
  @media (min-width: 1024px) {
   font-size: 27px;
  }
`

const WorkingHoursHeader = styled.h4`
 font-family: Roboto, sans-serif;
 font-weight: 700;
 margin: 0;
 padding-bottom: 15px;
 text-align: center;
  @media (min-width: 600px) {
   font-size: 23px;
  }
  @media (min-width: 1024px) {
   font-size: 27px;
  }
`

const WorkingHours = styled.div`
 font-family: Roboto, sans-serif;
 font-size: 12px;
 display: flex;
 justify-content: space-between;
 padding: 3px 0;
 @media (min-width: 600px) {
   font-size: 16px;
   padding: 5px 0;
  }
 @media (min-width: 1024px) {
   font-size: 20px;
   padding: 10px 0;
  }
`
const WorkingHoursContainer = styled.div`
 color: ${color14};
 background: ${color2};
 padding: 20px 0;
`

const Days = styled.p`
 text-transform: uppercase;
 font-family: Roboto, sans-serif;
 margin: 0;
`

const Hours = styled.p`
 font-family: Roboto, sans-serif;
 margin: 0 0 3px 0;
 text-align: right;
`
const ContactInfoContainer = styled.div`
 margin: 20px 0;
 flex-grow: 1;
`

const ContactInfoCtr = styled.div`
 display: flex;
 flex-grow: 1;
`

const renderContact = (contact) => {
  const Paragraph = styled.p`
  color: ${color12};
  font-size: 12px;
  font-family: Roboto, sans-serif;
  margin: 0;
  line-height: 170%;
  @media (min-width: 600px) {
   font-size: 16px;
  }
  @media (min-width: 1024px) {
   font-size: 20px;
  }
 `
  return (
    <ContactInfoContainer>
      {map((p) =>
        <Paragraph key={p}>{p}</Paragraph>
        , contact
      )}
    </ContactInfoContainer>
  )
}


const Contact = () => {
  const { weekWorkingHours, saturdayWorkingHours, contact } = contactInfo
  return (
    <Wrapper id={'kontakt'} >
      <ContactBox>
        <Header >
          Kontakt
        </Header>
        <MaxWidthContainer>
          <ContactInfoCtr>
            {renderContact(contact)}
            <Logo />
          </ContactInfoCtr>
        </MaxWidthContainer>
        <WorkingHoursContainer>
          <WorkingHoursHeader>
            Godziny otwarcia
          </WorkingHoursHeader>
          <MaxWidthContainer>
            <WorkingHours>
              <Days>poniedziałek - piątek</Days>
              <Hours>od {weekWorkingHours.start} do {weekWorkingHours.end}</Hours>
            </WorkingHours>
            <WorkingHours>
              <Days>sobota</Days>
              <Hours>{saturdayWorkingHours.text}</Hours>
            </WorkingHours>
          </MaxWidthContainer>
        </WorkingHoursContainer>
      </ContactBox>
      <MapContainer>
        <Map isMarkerShown />
      </MapContainer>
    </Wrapper>
  )
}


export default Contact
