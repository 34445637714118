import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
 max-width: 1280px;
 width: 90%;
 margin: 0 auto;
`

const MaxWidthContainer = ({ children }) => {
 return (
  <Container>
   {children}
  </Container>
 )
}

export default MaxWidthContainer
