import React, { useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery } from "gatsby"

import styled, { createGlobalStyle } from 'styled-components'
import Menu from "./Menu"
import Overlay from './Hamburger/Overlay'

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    @media(max-width: 1023px){
      ${props => props.showMenu ? 'overflow: hidden;' : null}
    }
    overflow-x: hidden;
  }
`
const StyledContent = styled.div`
  padding-top: 50px;
`

const Layout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <>
      <Overlay visible={showMenu} onClick={() => setShowMenu(!showMenu)} />
      <GlobalStyle showMenu={showMenu} />
      <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
      <StyledContent>
        {children}
      </StyledContent>
    </>)
}

export default Layout
