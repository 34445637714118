import thumbnail1 from '../images/galeria1-thumbnail.jpg'
import photo1 from '../images/galeria1.jpg'
import thumbnail2 from '../images/galeria2-thumbnail.jpg'
import photo2 from '../images/galeria2.jpg'
import thumbnail3 from '../images/galeria3-thumbnail.jpg'
import photo3 from '../images/galeria3.jpg'
import thumbnail4 from '../images/galeria4-thumbnail.jpg'
import photo4 from '../images/galeria4.jpg'
import thumbnail5 from '../images/galeria5-thumbnail.jpg'
import photo5 from '../images/galeria5.jpg'
import thumbnail6 from '../images/galeria6-thumbnail.jpg'
import photo6 from '../images/galeria6.jpg'

export default [
 {
  thumbnail: thumbnail1,
  photo: photo1,
  darkX: false,
  vertical: false
 },
 {
  thumbnail: thumbnail2,
  photo: photo2,
  darkX: true,
  vertical: false
 },
 {
  thumbnail: thumbnail3,
  photo: photo3,
  darkX: true,
  vertical: true
 },
 {
  thumbnail: thumbnail4,
  photo: photo4,
  darkX: true,
  vertical: true
 },
 {
  thumbnail: thumbnail5,
  photo: photo5,
  darkX: true,
  vertical: false
 },
 {
  thumbnail: thumbnail6,
  photo: photo6,
  darkX: true,
  vertical: true
 },
]