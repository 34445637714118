import React from 'react'
import styled from 'styled-components'
import HamburgerIcon from './HamburgerIcon'

const HamburgerMenu = ({ showMenu, setShowMenu }) => {
  return <HamburgerIcon showMenu={showMenu} onClick={() => { setShowMenu(!showMenu) }} />
}


export default HamburgerMenu
